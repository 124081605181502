//font-family
$title-font-family: 'Poppins', sans-serif;
$main-font-family: 'Lato', sans-serif;

$border: 0.1rem solid #e5eef7;
$transition: 0.35s cubic-bezier(0.3, 0.1, 0.3, 1);

//MONTH
$january: #6271b6;
$february: #42a9ce;
$march: #42cece;
$april: #42baa0;
$may: #49d35f;
$june: #68b61b;
$july: #ded41b;
$august: #ff782c;
$september: #ce6c4d;
$october: #ce4d4d;
$november: #bd6d97;
$december: #8d6ccb;

//SYSTEM COLORS
$error: #c33e38;
$red-pressed: #efccca;
$red-hover: #efccca;
$success: #67ac5b;
$warning: #f09b43;

//BLUE
$blue-7: #124da8;
$blue-6: #1f66d1;
$brand-blue-1: #02abe3;
$blue-4: #81b3ff;
$blue-3: #b8d5fd;
$blue-2: #c7d9f4;
$blue-1: #e9f0fa;
$brand-blue-1: #02abe3;
$brand-blue-4: #4791ce;
$background-message: #e6f0ff;

//GREY-BLUE
$brand-blue-5: #024d7c;
$grey-blue-3: #7388ae;
$grey-blue-2: #b4bfd3;
$grey-blue-1: #f4f6f9;

//BLACK
$black-4: #212121;
$black-3: #666666;
$black-2: #b3b3b3;
$black-1: #e0e0e0;

//WHITE
$white: #ffffff;

//SINGLE COLORS
$rippling-brown: #4b2f3b;

@import '../../../styles/breakpoints';

@function breakpoint-min(
  $name,
  $breakpoints: $responsive-breakpoints,
  $breakpoint-names: map-keys($breakpoints)
) {
  $n: index($breakpoint-names, $name);
  @if not $n {
    @error "breakpoint `#{$name}` not found";
  }

  @return map-get($breakpoints, $name);
}

@function breakpoint-max($name, $breakpoints: $responsive-breakpoints) {
  @return breakpoint-min($name, $breakpoints) - 0.02;
}

// The maximum value is calculated as the minimum of the next one less 0.02px
// to work around the limitations of `min-` and `max-` prefixes and viewports with fractional widths.
// See https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Uses 0.02px rather than 0.01px to work around a current rounding bug in Safari.
// See https://bugs.webkit.org/show_bug.cgi?id=178261
//
//  >> if-smaller-than(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//  @media (max-width: 575.98px) { @content }
@mixin if-smaller-than($breakpoint-name) {
  $max: breakpoint-max($breakpoint-name);

  @media (max-width: $max) {
    @content;
  }
}

//  >> if-bigger-than(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//  @media (min-width: 576px) { @content }
@mixin if-bigger-than($breakpoint-name) {
  $min: breakpoint-min($breakpoint-name);

  @media (min-width: $min) {
    @content;
  }
}

// Media that spans multiple breakpoint widths.
// Makes the @content apply between the min and max breakpoints
// inspired by bootstrap solution https://github.com/twbs/bootstrap/blob/master/scss/mixins/_breakpoints.scss

//  >> if-between(sm, md (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//  @media (min-width: 576px) and (max-width: 767.98px) { @content }
@mixin if-between($name_min, $name_max, $breakpoints: $responsive-breakpoints) {
  $min: breakpoint-min($name_min, $breakpoints);
  $max: breakpoint-max($name_max, $breakpoints);

  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin flex-center {
  display: flex;
  align-items: center;
}

@mixin flex-start {
  display: flex;
  align-items: flex-start;
}

@mixin flex-end {
  display: flex;
  justify-content: flex-end;
}

@mixin flex-start-center {
  @include flex-start;
  justify-content: center;
}

@mixin flex-center-between {
  @include flex-center;
  justify-content: space-between;
}

@mixin flex-center-end {
  @include flex-center;
  justify-content: flex-end;
}

@mixin flex-center-around {
  @include flex-center;
  justify-content: space-around;
}

@mixin flex-center-center {
  @include flex-center;
  justify-content: center;
}

@mixin flex-baseline-between {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

@mixin flex-start-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flex-end-between {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

@mixin flex-column {
  display: flex;
  flex-direction: column;
}

@mixin flex-column-center {
  @include flex-column;
  align-items: center;
}

@mixin flex-column-start {
  @include flex-column;
  align-items: flex-start;
}

@mixin no-btn {
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}

@mixin all-content {
  @include flex-column;
  height: calc(100vh - 7rem);
  justify-content: space-between;
}

$font-size-h1: 3.6rem;
$font-size-h2: 2.4rem;
$font-size-h3: 1.8rem;
$font-size-regular: 1.6rem;
$font-size-small: 1.4rem;
$font-size-m2: 1.3rem;
$font-size-m3: 1.2rem;

$font-weight-hair-line: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-heavy: 900;

$device-xs: 0;
$device-sm: 576px;
$device-md: 768px;
$device-lg: 992px;
$device-xl: 1200px;
$device-xxl: 1400px;

// Breakpoints for mixins
$responsive-breakpoints: (
  xs: $device-xs,
  sm: $device-sm,
  md: $device-md,
  lg: $device-lg,
  xl: $device-xl,
  xxl: $device-xxl,
);

:export {
  xs: $device-xs;
  sm: $device-sm;
  md: $device-md;
  lg: $device-lg;
  xl: $device-xl;
  xxl: $device-xxl;
}

.wrapper {
  padding-top: 4rem;
  background-color: $white;
  margin-top: 10rem;

  @include if-smaller-than(md) {
    margin-top: 8rem;
  }

  .main-container {
    flex-direction: column;
    padding: 0 2rem;

    .container {
      @include flex-center-between;
      width: 100%;
      margin-bottom: 2.5rem;

      @include if-smaller-than(md) {
        flex-direction: column;
      }

      .contacts {
        @include flex-center;

        @include if-smaller-than(md) {
          @include flex-center;
          margin-top: 2rem;
        }

        svg {
          margin-right: 1rem;
        }
      }
    }
  }

  .copyright {
    width: 100%;
    text-align: end;
    padding: 1rem 0;
    border-top: 0.1rem solid $grey-blue-1;
    color: $grey-blue-3;

    @include if-smaller-than(md) {
      text-align: center;
    }
  }
}
